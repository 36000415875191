


















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Axe from '@/assets/img/clients/axe.vue'
import Burgerking from '@/assets/img/clients/burgerking.vue'
import Durex from '@/assets/img/clients/durex.vue'
import Ebay from '@/assets/img/clients/ebay.vue'
import Gameinsight from '@/assets/img/clients/gameinsight.vue'
import Garnier from '@/assets/img/clients/garnier.vue'
import KFC from '@/assets/img/clients/kfc.vue'
import Sber from '@/assets/img/clients/sber.vue'
import Sprite from '@/assets/img/clients/sprite.vue'
import UCBrowser from '@/assets/img/clients/ucbrowser.vue'
import Xbox from '@/assets/img/clients/xbox.vue'
import Yota from '@/assets/img/clients/yota.vue'

@Component({
  components: {
    Axe,
    Burgerking,
    Durex,
    Ebay,
    Gameinsight,
    Garnier,
    KFC,
    Sber,
    Sprite,
    UCBrowser,
    Xbox,
    Yota,
  },
})
export default class Clients extends Vue {
}
