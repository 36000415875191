<template>
  <section id="subscribe">
    <div class="header__count">
      <img src="@/assets/img/100mln.svg" class="header__count_100">
      <img src="@/assets/img/subscribers_font.svg" class="header__count_subs">
    </div>
    <div class="header__partners">
      ПАРТНЁРСКИЕ ПЛОЩАДКИ
    </div>
    <div class="header__eskadra">
      <img src="@/assets/img/eskadra.svg">
    </div>
    <div class="header__subtext">
      ПОДПИШИТЕСЬ НА НАШ ЕЖЕМЕСЯЧНЫЙ ОБЗОР ВИРУСНЫХ ИНТЕРНЕТ-ТРЕНДОВ
    </div>

    <form class="subscribe__form" @submit.prevent="onsubmit" ref="inputForm" v-if="!formSuccess">
      <div class="input__container">
        <input v-model="userEmail" type="email" name="email" class="input__input" placeholder="Введите почту">
      </div>
      <div class="email__checkbox">
        <input id="emailCheckbox" v-model="emailCheckbox" type="checkbox">
        <label for="emailCheckbox">Согласен получать рассылку от MDK Creative.</label>
      </div>
      <div v-if="inputError" class="input__error">{{ inputError }}</div>
      <button type="submit" class="subbutton">ПОДПИСАТЬСЯ</button>
    </form>
    <div v-else class="subscribe__success">
      ДАННЫЕ УСПЕШНО ОТПРАВЛЕНЫ. СПАСИБО!
    </div>
  </section>
</template>

<script>
const emailRegex = /^[a-zA-Zёа-яЁА-Я0-9_\.\-\+]{1,64}@[a-zA-Zёа-яЁА-ЯЁёäöüÄÖÜßèéû0-9][a-zA-Zёа-яЁА-ЯЁёäöüÄÖÜßèéû0-9\.\-]{0,253}\.[a-zA-Zёа-яЁА-Я]{2,10}$/gi // eslint-disable-line

export default {
  data () {
    return {
      inputError: null,
      formSuccess: false,

      userEmail: null,
      emailCheckbox: false,
    }
  },

  methods: {
    async onsubmit () {
      const value = this.userEmail
      if (!value || value === '') {
        this.inputError = 'Пожалуйста введите email.'
        return
      }

      if (!emailRegex.test(value)) {
        this.inputError = 'Введен неверный email.'
        return
      }

      if (!this.emailCheckbox) {
        this.inputError = 'Подтвердите согласие на получение рассылки.'
        return
      }

      this.inputError = null

      // const form = new FormData(this.$refs.inputForm)
      await fetch('/form', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        // redirect: 'follow', // manual, *follow, error
        // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          email: value,
        }), // body data type must match "Content-Type" header
      })

      this.formSuccess = true
    },
  },
}
</script>

<style lang="stylus" scoped>
#subscribe
  min-height 100vh
  display flex
  flex-direction column
  align-items center

  .header
    &__eskadra
      margin-top 20px

      img
        width 416px
        height 31px

    &__count
      display flex
      flex-direction row
      width 745px
      height 37px

      &_100
        margin-right 20px
        height 37px

      &_subs
        width 456px
        height 37px

    &__partners
      color red
      font-weight 700
      font-size 30px
      margin-top 30px

    &__subtext
      margin-top 120px
      color red
      font-weight 700
      font-size 40px

  .subbutton
    background red
    width 300px
    height 49px
    box-sizing border-box
    border-radius 10px
    border none
    color white
    font-size 18px
    font-weight 500
    outline none
    cursor pointer

  .subscribe__form
    display flex
    flex-direction column
    align-items center

  .input__container
    margin 50px 0 0px 0
    background url('../assets/img/input.png')
    width 527px
    height 115px
    padding 42px 50px
    box-sizing border-box

    .input__input
      background transparent
      color white
      border none
      // border 7px solid white
      // border-radius 50px
      // padding 10px 10px
      width 100%
      font-size 24px
      font-weight 300
      outline none

      &::-webkit-input-placeholder
        color white

  .email__checkbox
    margin 0px 0 20px 0

  .input__error
    margin-bottom 10px

  .subscribe__success
    margin 20px 10px

@media screen and (max-width 850px)
  #subscribe
    max-width 340px
    margin 100px auto 0 auto
    min-height 0

    .header
      &__count
        flex-direction column
        width 100%
        height auto
        align-items center
        max-width 315px

        &_100
          // width 100px
          height 28px
          margin 0
          width 100%

        &_subs
          // width 200px
          margin-top 15px
          height 28px
          width 100%

      &__partners
        font-size 20px

      &__eskadra
        width 235px

        img
          width 235px

      &__subtext
        font-size 18px
        text-align center
        margin-top 30px

    .subscribe__form
      width 100%

    .input__container
      width 100%
      background-size contain
      background-repeat no-repeat
      padding 26px 28px
      margin 40px 0 0 0
      height 78px

      .input__input
        font-size 16px

    .email__checkbox
      margin 0px 0 20px 0
      padding 0 20px
      display flex

      input
        margin-right 5px

      label
        display block
</style>
