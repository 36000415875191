




























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HelloWorld extends Vue {
  data () {
    return {
      items: [{
        id: 1,
        icon: require('@/assets/img/vk.svg'),
        number: '30 +',
        unit: ' млн.',
        caption: 'на 12 площадках',
        top: 'ТОП-6 пабликов',
        class: 'vk',
        // text: 'Контентная\nи бренд стратегия',
      }, {
        id: 2,
        icon: require('@/assets/img/tg.svg'),
        number: '640 +',
        unit: ' тыс.',
        caption: 'подписчиков',
        top: 'ТОП-8 каналов',
        class: 'tg',
      }, {
        id: 3,
        icon: require('@/assets/img/twitter.svg'),
        number: '245 +',
        unit: ' тыс.',
        caption: 'подписчиков',
        top: 'ТОП-3 каналов',
        class: 'twitter',
      }],
    }
  }
}
