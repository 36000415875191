




















import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class HelloWorld extends Vue {
  data () {
    return {
      items: [{
        id: 1,
        icon: require('@/assets/img/road.svg'),
        text: 'Контентная\nи бренд стратегия',
        textMobile: 'Контентная\nи бренд стратегия',
      }, {
        id: 2,
        icon: require('@/assets/img/chat.svg'),
        text: 'Создание\nуникальных\nиллюстраций',
        textMobile: 'Создание уникальных\nиллюстраций',
      }, {
        id: 3,
        icon: require('@/assets/img/landscape.svg'),
        text: 'Креативная\nподдержка\n(общение в чатах)',
        textMobile: 'Креативная поддержка\n(общение в чатах)',
      }, {
        id: 4,
        icon: require('@/assets/img/virus.svg'),
        text: 'Вирусные истории\nи спецпроектыя',
        textMobile: 'Вирусные истории\nи спецпроектыя',
      }, {
        id: 5,
        icon: require('@/assets/img/art.svg'),
        text: 'Креативный\nкопирайт\nпостов/сообщений',
        textMobile: 'Креативный копирайт\nпостов/сообщений',
      }, {
        id: 6,
        icon: require('@/assets/img/idea.svg'),
        text: 'Креативный\nконсалтинг\nпо идеям и трендам',
        textMobile: 'Креативный консалтинг\nпо идеям и трендам',
      }, {
        id: 7,
        icon: require('@/assets/img/meme.svg'),
        text: 'Мемы в тренде\nи бренд-ценностях',
        textMobile: 'Мемы в тренде\nи бренд-ценностях',
      }, {
        id: 8,
        icon: require('@/assets/img/bot.svg'),
        text: 'Разработка\nконцепта и древа\nчат-ботов',
        textMobile: 'Разработка концепта\nи древа чат-ботов',
      }, {
        id: 9,
        icon: require('@/assets/img/loud.svg'),
        text: 'Продвижение анонсов\nпроектов/продуктов\nи кинопремьер',
        textMobile: 'Продвижение анонсов\nпроектов/продуктов\nи кинопремьер',
      }],
    }
  }
}
