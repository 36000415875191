import _Vue, { PluginObject } from 'vue'

// const iosUserAgentRegex = /iPad|iPhone|iPod/
// const androidUserAgentRegex = /android/i

const Plugin: PluginObject<any> = {
  install: (Vue: typeof _Vue, _?: any) => {
    Vue.prototype.$isMobile = (): boolean => {
      if (window.innerWidth < 550) {
        return true
      }

      return false
    }
  },
}

export default Plugin
