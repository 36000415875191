import { render, staticRenderFns } from "./Audience.vue?vue&type=template&id=268a21e0&scoped=true&"
import script from "./Audience.vue?vue&type=script&lang=ts&"
export * from "./Audience.vue?vue&type=script&lang=ts&"
import style0 from "./Audience.vue?vue&type=style&index=0&id=268a21e0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268a21e0",
  null
  
)

export default component.exports