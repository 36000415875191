<template>
  <section id="contacts">
    <h2 v-if="!$isMobile()">БУДЬ НА СВЯЗИ С MDK</h2>
    <h2 v-else>БУДЬ НА СВЯЗИ<br>С MDK</h2>

    <div class="contacts__container">
      <div class="contacts__item contacts__mail">
        <img src="@/assets/img/email.svg">
        <div class="contacts__item-text">NEWS<div class="smallat">@</div>MDK.IS</div>
      </div>
      <!-- <div class="contacts__item contacts__phone">
        <img src="@/assets/img/phone.svg">
        <div class="contacts__item-text">+7 987 654 32 10</div>
      </div> -->
    </div>

    <!-- <div class="contacts__social" v-if="$isMobile()">
      <a class="link" href="https://vk.com" rel="noopener noreferrer" target="_blank">
        <img src="@/assets/img/vk.svg">
      </a>
      <a class="link" href="https://vk.com" rel="noopener noreferrer" target="_blank">
        <img src="@/assets/img/tg.svg">
      </a>
      <a class="link" href="https://vk.com" rel="noopener noreferrer" target="_blank">
        <img src="@/assets/img/twitter.svg">
      </a>
    </div> -->

    <div class="footer">
      <div class="copyright">
        <div>© MDK Creative Agency {{new Date().getFullYear()}}</div>
        <div>ООО Меркурий ИНН 9710081120</div>
        <div>ОГРН 1207700016996</div>
      </div>

      <a class="privacy" href="/privacy.pdf" target="_blank">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</a>
      <div class="backtop" @click="totop">НАВЕРХ</div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    totop () {
      // console.log('totop', this)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style lang="stylus" scoped>
#contacts
  min-height 100vh
  display flex
  flex-direction column
  justify-content center
  position relative

  .footer
    position absolute
    bottom 30px
    display flex
    font-size 16px
    justify-content space-between
    width 100%
    color #a0a0a0
    align-items flex-end

    // max-width 940px
    .privacy, .backtop
      font-weight 600
      color #a0a0a0
      text-decoration none
      cursor pointer

    .copyright
      display flex
      flex-direction column
      gap 4px

  h2
    font-size 66px
    font-weight 700
    text-align center

  .contacts__container
    display flex
    justify-content center
    width 100%

    .contacts__item
      display flex
      width 100%
      align-items center

      &-text
        color red
        font-weight 500
        font-size 39px
        line-height 40px
        display flex

        .smallat
          font-size 33px
          line-height 40px
          margin-top -2px

      &.contacts__mail
        justify-content center

        img
          // margin-top 6px
          width 40px
          height 28px
          margin-right 16px

      &.contacts__phone
        img
          width 41px
          height 41px
          margin-right 25px

@media screen and (max-width 850px)
  #contacts
    min-height 0
    margin 70px auto 0 auto
    max-width 340px

    .footer
      bottom 0
      position relative
      // height 30px
      margin-top 30px
      margin 40px 0 30px 0
      flex-direction row
      flex-wrap wrap
      font-size 12px

      .privacy, .backtop
        margin-top 7px

    h2
      font-size 36px

    .contacts__container
      flex-direction column
      margin-top 30px

      .contacts__item
        &-text
          font-size 30px

          .smallat
            font-size 256x

        &.contacts__phone
          img
            width 41px
            height 41px
            margin-right 16px
</style>
