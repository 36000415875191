import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'

import VueScrollactive from './plugins/scrollactive/index'
import IsMobile from './plugins/isMobile'

Vue.use(VueScrollactive)
Vue.use(IsMobile)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
