<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/img/logo.svg">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Intro />
    <Capabilities />
    <Audience />
    <Wiki />
    <Clients />
    <Subscribe />
    <Contacts />
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from '@/components/Intro.vue'
import Capabilities from '@/components/Capabilities.vue'
import Audience from '@/components/Audience.vue'
import Clients from '@/components/Clients.vue'
import Subscribe from '@/components/Subscribe.vue'
import Contacts from '@/components/Contacts.vue'
import Wiki from '@/components/Wiki.vue'

export default {
  name: 'Home',
  components: {
    Intro,
    Capabilities,
    Audience,
    Clients,
    Subscribe,
    Contacts,
    Wiki,
  },
}
</script>
