<template>
  <div id="app">
    <div class="bg">
      <div class="earth_img" />
      <div class="stars" />
    </div>
    <div class="logo">
      <img src="@/assets/img/logo.svg">
    </div>
    <div class="burger" @click="triggerMenu">
      <img src="@/assets/img/burger.svg">
    </div>
    <div class="menuwindow" v-if="menuOpen">
      <div class="menu__container">
        <div class="items">
          <a @click="triggerMenu" href="#intro" class="items__link">ГЛАВНАЯ</a>
          <a @click="triggerMenu" href="#capabilities" class="items__link">ВОЗМОЖНОСТИ</a>
          <a @click="triggerMenu" href="#audience" class="items__link">ПЛОЩАДКИ</a>
          <a @click="triggerMenu" href="#clients" class="items__link">КЛИЕНТЫ</a>
          <a @click="triggerMenu" href="#contacts" class="items__link">КОНТАКТЫ</a>
        </div>
      </div>
    </div>
    <div class="navigation">
      <scrollactive class="dots" :offset="300" :scrollOffset="100">
        <span data-section-selector="#intro" hash="123" class="dot scrollactive-item" />
        <span data-section-selector="#capabilities" class="dot scrollactive-item" />
        <span data-section-selector="#audience" class="dot scrollactive-item" />
        <span data-section-selector="#wiki" class="dot scrollactive-item" />
        <span data-section-selector="#clients" class="dot scrollactive-item" />
        <span data-section-selector="#subscribe" class="dot scrollactive-item" />
        <span data-section-selector="#contacts" class="dot scrollactive-item" />
      </scrollactive>

      <!-- <div class="social">
        <a class="link" href="https://vk.com/mudakoff" rel="noopener noreferrer" target="_blank">
          <img src="@/assets/img/vk.svg">
        </a>
        <a class="link" href="https://t.me/mudak" rel="noopener noreferrer" target="_blank">
          <img src="@/assets/img/tg.svg">
        </a>
        <a class="link" href="https://twitter.com/mudakoff" rel="noopener noreferrer" target="_blank">
          <img src="@/assets/img/twitter.svg">
        </a>
      </div> -->
    </div>
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menuOpen: false,
    }
  },

  methods: {
    triggerMenu () {
      this.menuOpen = !this.menuOpen
    },
  },

  metaInfo () {
    return {
      bodyAttrs: {
        class: [this.menuOpen ? 'hidden' : ''],
      },
    }
  },
}
</script>

<style lang="stylus">
#app
  font-family 'Montserrat', sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  // text-align center
  // margin-top 60px
  // background url('./assets/img/earth.png')
  background-size contain
  background-repeat no-repeat
  position relative

  .bg
    position absolute
    left 0
    top 0
    z-index -1
    width 100%

    .earth_img
      position relative
      width 100%
      height 100vh
      // background url('./assets/img/earth.png')
      background-size cover
      // background-repeat no-repeat
      // background-position 50% 50%
      // background 50% 50% url('./assets/img/earth.png') cover
      background center / cover no-repeat url('./assets/img/earth.png'), linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%)
      background-blend-mode multiply
      z-index 10
      // background red

    img
      width 100%

    .stars
      position relative
      opacity 0.34
      margin-top -100px
      z-index 5
      background green
      width 100%
      height 200vh
      background center / cover no-repeat url('./assets/img/stars.png'), linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 10%), linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%)
      background-blend-mode multiply

  .logo
    width 169px
    top 30px
    right 44px
    position absolute

  .burger
    // width 169px
    top 75px
    left 75px
    position absolute
    cursor pointer

  .menuwindow
    // width 100vw
    height 100vh
    background black
    left 0
    top 0
    right 0
    // bottom 0
    position absolute
    z-index 1000

    .menu__container
      position relative
      width 100%
      height 100%

    .items
      display flex
      flex-direction column
      left 50%
      top 50%
      position absolute
      transform translate(-50%, -50%)

      .items__link
        color white
        text-decoration none
        font-size 67px
        font-weight 900
        cursor pointer

        &:hover
          color red

  .navigation
    position fixed
    right 44px
    top 35%
    width 30px
    height 55%
    // background red
    justify-content space-between
    display flex
    flex-direction column
    z-index 100

    .dots
      display flex
      flex-direction column
      align-items center

      .dot
        margin 6px 0
        width 15px
        height 15px
        background white
        border-radius 100%
        cursor pointer

        &.is-active
          background red
          box-shadow 0px 0px 20px 5px rgba(255, 0, 0, 1), inset 0px 0px 3px 1px #E45125

html
  overflow-x hidden

body
  background black
  color white
  margin 0
  overflow-x hidden

  &.hidden
    overflow hidden

.container
  max-width 940px
  margin 0 auto
  position relative

@media screen and (max-width 850px)
  .container
    width 100%

  #app
    .navigation
      display none

    .logo
      width 126px
      top 17px
      left 50%
      position absolute
      transform translateX(-50%)

    .burger
      left 30px
      top 30px
      z-index 20

    .menuwindow
      .items
        .items__link
          font-size 32px
</style>

<style>
@font-face {
  font-family: "SFDistantGalaxy";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/SFDistantGalaxyItalic.woff2") format("woff2"),
    url("/fonts/SFDistantGalaxyItalic.woff") format("woff"),
    url("/fonts/SFDistantGalaxyItalic.ttf") format("truetype");
}
</style>
