import Scrollactive from './scrollactive.vue'
import _Vue, { PluginObject } from 'vue'

const Plugin: PluginObject<any> = {
  install: (Vue: typeof _Vue, _?: any) => {
    Vue.component('scrollactive', Scrollactive)
  },
}

export default Plugin
